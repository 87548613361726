<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-icon :color="status.color" v-bind="attrs" v-on="on">
        {{ status.icon }}
      </v-icon>
    </template>
    <span> {{ status.text }}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    pago: String
  },

  computed: {
    status() {
      if (this.pago == "S") {
        return {
          icon: "mdi-cash-check",
          text: "Pago",
          color: "success"
        };
      }
      return {
        icon: "mdi-cash-remove",
        text: "Não recebeu",
        color: "error"
      };
    }
  }
};
</script>

<style></style>
